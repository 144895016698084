.appstore {
  @mixin appstoreLG {
  }

  padding: 3rem;
  position: fixed;
  bottom: calc($appstore-height * -1);
  background-color: $color-yellow;
  height: $appstore-height;
  width: 100%;
  max-width: $pc-max-width;
  @include flexCenter(row);
  justify-content: center;
  gap: 3rem;
  transition: all 0.2s ease-in;

  @include multiRespond(pc, tab) {
    gap: 6rem;
  }

  &.visible {
    bottom: 0;
  }

  &__link {
    display: flex;
    align-items: baseline;
    gap: 0.5rem;
  }

  &__link-btn {
    height: 6rem;
    @include multiRespond(pc, tab) {
      height: 4rem;
    }
  }

  &__device {
    &--macos {
      height: 4.5rem;
      @include multiRespond(pc, tab) {
        height: 3.5rem;
      }
    }

    &--ios {
      height: 6rem;
      @include multiRespond(pc, tab) {
        height: 4rem;
      }
    }
  }
}
