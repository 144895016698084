.pc-finder {
  & .scene-section__content {
    padding-bottom: calc($appstore-height + 6rem);
  }
  &__sukusho {
    width: 120%;
    object-fit: contain;

    @include respond(pc) {
      width: 65%;
    }

    @include respond(tab) {
      width: 85%;
    }

    & img {
      width: 100%;
      object-fit: contain;
    }
  }

  &__lower {
    position: relative;
    @include flexCenter(column);
    gap: 2.5rem;
  }

  &__text {
    padding-right: $section-padding-h;
    padding-left: $section-padding-h;
    text-align: right;

    &--bottom {
      text-align: center;

      & h3 {
        color: $color-brown;
        line-height: 2.2rem;
      }
    }
  }

  &__content {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 100vh;
  }

  &__popup {
    bottom: -8rem;
    width: 45rem;
  }
}
