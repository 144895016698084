.hamburger {
  &__wrapper {
    width: 3.6rem;
    height: 2.4rem;
    position: relative;
    cursor: pointer;
  }

  &__content {
    width: 100%;
    height: 3px;
    background-color: $color-black;
    position: absolute;
    transition: all 0.2s ease-out;
    &--1 {
      top: 0;
      right: 0;

      &.open {
        top: calc(50% - 1px);

        transform: rotate(135deg);
      }
    }

    &--2 {
      @include vertCenter;
      &.open {
        opacity: 0;
      }
    }

    &--3 {
      bottom: 0;
      right: 0;
      &.open {
        top: calc(50% - 1px);
        left: 0;
        transform: rotate(-135deg);
      }
    }
  }
}
