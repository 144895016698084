.file-upload {
  --btn-bkg: $color-black;
  --btn-clr: $color-white;
  --btn-wp: $color-grey;

  margin-top: 1rem;
  margin-bottom: 1rem;
  @include flexCenter(row);
  width: 100%;
  @include respond(pc) {
    width: 50rem;
  }

  &__label {
    @include inputStyle;
    @include body_p;
    padding: 1rem 2rem;
    width: 100%;
    margin-right: 1.5rem;
  }
  &__button {
    position: relative;
    padding: 0.5rem;
    border: 1px solid #ccc;
    display: inline-block;
    min-width: 4.3rem;
    height: 4.3rem;
    min-height: 4.3rem;
    max-height: 4.3rem;
    border-radius: 0.6rem;
    background-color: var(--btn-bkg);
    color: var(--btn-clr);
    cursor: pointer;

    @include btn_centerwipe(var(--btn-wp));

    & i {
      @include absCenter;
      font-size: 2.5rem;
    }
  }
}
