.pc-detail {
  &__text {
    @include horPadding;
    @include respond(pc) {
      position: absolute;
      top: 8rem;
      z-index: 2;
      & p {
        width: 45%;
      }
    }
  }

  &__sukusho-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: end;
    overflow: hidden;
    justify-content: center;
    transform-origin: 100% center;
  }
  &__sukusho {
    width: 90rem;

    transform: translateX(10%);
    @include respond(ssp) {
      width: 90%;
    }
    @include respond(pc) {
      width: unset;
      height: 70%;

      transform: translateX(0);
    }

    @include respond(tab) {
      width: 80%;
    }
  }
}
