// * OFFSET
$header-height-pc: 0;
$header-height-sp: 0 !default;
$header-height: var(--header-height);

// * PADDING
$section-padding-sp-h: 3rem;
$section-padding-sp-v: 3rem;
$section-padding-pc-h: 3rem;
$section-padding-pc-v: 3rem;

$section-padding-v: var(--section-padding-v);
$section-padding-h: var(--section-padding-h);

$section-padding-pc: $section-padding-pc-v $section-padding-pc-h;
$section-padding-sp: $section-padding-sp-v $section-padding-sp-h;

$section-padding: var(--section-padding);

// * SIZE STANDARDS
$pc-max-width: 1000px;
$res-width-pc: 1000;

$res-width-sp: 750;

//--Breakpoints
$bkp-pc: 900;
$bkp-tab: 768;
$bkp-ssp: 700;
// $bkp-sp: 750;
// $bkp-tab: 1199;
// $bkp-pc: 1200;

$appstore-height-sp: 12rem;
$appstore-height-pc: 6rem;
$appstore-height-tab: 6rem;
$appstore-height: var(--appstore-height);
