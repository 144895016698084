#bkg-portal {
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  @include respond(pc) {
    @include flexCenter(column);
  }

  overflow: hidden;
  z-index: -1;
}

.film-bkg {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: $color-white;
  &__wrapper {
    height: 100%;
    overflow: hidden;
    @include respond(pc) {
      width: $pc-max-width;
    }
  }

  & img {
    position: absolute;
    object-fit: contain;
    width: 140%;
    left: -27rem;
    bottom: 0rem;
    @include respond(pc) {
      width: 120%;
      bottom: -30rem;
    }
  }
}

// .film-bkg {
//   width: 100%;
//   height: 100vh;
//   top: 0;
//   right: 0;
//   left: 0;
//   bottom: 0;
//   position: fixed;
//   z-index: -1;
//   overflow: hidden;
//   text-align: center;
//   @include flexCenter(column);

//   &__wrapper {
//     position: fixed;
//     height: 100vh;
//     top: 0;
//     left: 0;
//     @include respond(pc) {
//       max-width: $pc-max-width;
//     }
//     overflow: hidden;
//   }

//   & img {
//     position: absolute;
//     max-width: 130%;
//     left: -25rem;
//     bottom: 0;
//     max-height: 100%;
//     z-index: -1;

//     @include respond(pc) {
//       bottom: -25rem;
//       left: -20rem;
//     }
//   }
// }
