*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

:root {
  overflow-x: hidden;
  font-size: 62.5%;
  -webkit-text-size-adjust: none;
  @include auto-respond-pc(1000);
  @include auto-respond-pc(900);
  @include auto-respond-sp(899);
  @include auto-respond-sp(820);
  @include auto-respond-sp(800);
  @include auto-respond-sp(768);
  @include auto-respond-sp(750);
  @include auto-respond-sp(720);
  @include auto-respond-sp(640);
  @include auto-respond-sp(414);
  @include auto-respond-sp(390);
  @include auto-respond-sp(375);

  --header-height: #{$header-height-sp};
  --appstore-height: #{$appstore-height-sp};
  --section-padding: #{$section-padding-sp};
  --section-padding-v: #{$section-padding-sp-v};
  --section-padding-h: #{$section-padding-sp-h};

  @include respond(pc) {
    --header-height: #{$header-height-pc};
    --appstore-height: #{$appstore-height-pc};
    --section-padding: #{$section-padding-pc};
    --section-padding-v: #{$section-padding-pc-v};
    --section-padding-h: #{$section-padding-pc-h};
  }

  @include respond(tab) {
    --appstore-height: #{$appstore-height-tab};
  }
  --color-txt: #{$color-txt-lm};
  --color-bkg: #{$color-bkg-lm};
  --color-ph: #{$color-grey};

  // @include respond(dark) {
  //   --color-txt: #{$color-txt-dm};
  //   --color-bkg: #{$color-bkg-dm};
  // }
}

html {
  overflow-x: hidden;
}

body {
  background-color: white;
  box-sizing: border-box;
  padding-bottom: env(safe-area-inset-bottom);
  color: $color-txt;
  background-color: $color-bkg;

  @include respond(pc) {
    background-color: rgba($color: $color-black, $alpha: 0.4);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  //   white-space: pre-wrap;
}

body::before {
  content: '';
  overflow-x: hidden;
  width: 100%;
  height: 100%;
}

main {
  position: relative;

  @include respond(pc) {
    width: 100vw;
    max-width: 1000px;
    overflowx: hidden;
  }
}

section {
  padding: 0;
  position: relative;
  height: 100vh;
}

ul {
  list-style-position: inside;
}

input::placeholder {
  color: $color-ph;
}

button,
.btn {
  @include resetButton;
  text-decoration: none;
  &:disabled {
    cursor: default;
  }
}

.sp-only {
  @include respond(pc) {
    width: 0;
    height: 0;
    visibility: hidden;
  }
}

.pc-only {
  width: 0;
  height: 0;
  visibility: hidden;

  @include respond(pc) {
    width: unset;
    height: unset;
    visibility: unset;
  }
}
