@mixin modalBkgExitAnimation() {
  animation: modalEntrance 200ms ease-in;

  @keyframes modalEntrance {
    0% {
      opacity: 1;
      backdrop-filter: blur(5px);
    }
    100% {
      opacity: 0;
      backdrop-filter: blur(0px);
    }
  }
}

@mixin modalBkgAnimation() {
  animation: modalEntrance 200ms ease-in;

  @keyframes modalEntrance {
    0% {
      opacity: 0;
      backdrop-filter: blur(0px);
    }
    100% {
      opacity: 1;
      backdrop-filter: blur(5px);
    }
  }
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  transition: all 200ms ease-out;
  z-index: 999999999;
  animation-fill-mode: both;
  backdrop-filter: blur(5px);
  &.entering,
  &.entered {
    @include modalBkgAnimation;
  }

  &.exiting,
  &.exited {
    opacity: 0;
    transition: all 0.2s;
  }
}

@keyframes modalEntrance {
  0% {
    opacity: 0;
    backdrop-filter: blur(0px);
  }
  100% {
    opacity: 1;
    backdrop-filter: blur(5px);
  }
}

.modal-bkg {
  height: 100%;
  width: 100%;
  transition: all 200ms ease-out;
  background-color: $color-black;
  opacity: 0.5;
}

.modal-card {
  @include absCenter;
  padding-top: 3rem;
  background-color: $color-white;

  display: flex;
  flex-direction: column;

  &__bar-items {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 3rem;
    padding: 1rem;

    @include respond(pc) {
      padding-left: 3rem;
    }
  }
  & .close-button {
    position: absolute;
    left: unset;
    top: 1rem;
    right: 1rem;

    @include respond(pc) {
      top: 2.5rem;
      right: 3rem;
    }

    // margin: 1.5rem auto 0 auto;
  }
}
