.paginator {
  display: flex;
  user-select: none;
  font-size: 1.8rem;
  & *:disabled {
    color: $color-grey;
    cursor: default;
  }

  & button {
    color: blue;
  }

  &__pages {
    & a {
      cursor: pointer;
      color: blue;
      text-decoration: underline;
      &.current {
        cursor: default;
        color: $color-grey;
        text-decoration: none;
      }
    }

    & > * {
      width: 3rem;
      line-height: 2rem;
      display: inline-block;
      text-align: center;
      border-right: 1px solid $color-black;

      &.upper {
        border-right: none;
      }
    }
  }
}
