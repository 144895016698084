.pc-browse {
  & h2 {
    margin-bottom: 2rem;

    transition: all 1s ease-out;
  }

  &__catch {
    text-align: center;
    @include horPadding;
  }

  &__image {
    position: relative;
    width: 200vw;
    text-align: center;

    @include respond(pc) {
      width: 100%;
    }

    @include respond(tab) {
      width: 150vw;
    }

    // transition: all 0.8s ease-in;
    &.left {
      transform: translateX(-70%);
    }

    & img {
      object-fit: contain;
    }
  }

  &__sukusho {
    width: 90%;
    opacity: 0;
    @include respond(pc) {
      width: 80%;
    }
  }

  &__popup {
    position: absolute;
    transform: scale(0);
    object-fit: contain;
    overflow: hidden;
    z-index: 1;
    // transition: all 0.3s ease-in;

    &.visible {
      transform: scale(1);
    }

    &--search {
      height: 15%;
      top: 7%;
      left: 5%;
    }

    &--filter {
      height: 12%;
      right: 5%;
      top: 7%;
    }
  }
}
