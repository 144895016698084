@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.eot?23jhoh');
  src: url('fonts/icomoon.eot?23jhoh#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?23jhoh') format('truetype'),
    url('fonts/icomoon.woff?23jhoh') format('woff'),
    url('fonts/icomoon.svg?23jhoh#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow_up:before {
  content: '\e900';
}
