.button-top {
  z-index: 99999;
  height: 4rem;
  width: 4rem;
  position: fixed;
  bottom: 2rem;
  right: 2rem;

  @include flexCenter(column);
  justify-content: center;

  border-radius: 50%;
  color: rgba($color-black, 0.8);
  background-color: rgba($color-white, 0.8);
  box-shadow: $shadow-01;

  font-size: 2.5rem;
  font-weight: 700;
  transition: bottom 0.3s ease-in;

  &.visible {
    visibility: visible;
    opacity: 1;

    transition: bottom 0.3s ease-in, opacity 0.2s linear;
  }

  &.hidden {
    visibility: hidden;
    opacity: 0;
    transition: bottom 0.3s ease-in, visibility 0s 0.2s, opacity 0.2s linear;
  }
}
