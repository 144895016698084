.privacy-policy {
  padding: 4rem;

  &__head {
    margin-bottom: 3rem;

    & h1 {
      text-align: center;
      margin-bottom: 1rem;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
}
