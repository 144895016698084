$box-height: 2rem;
$box-hyp: 2rem;
$line-width: 0.2rem;

@mixin closeButtonAnimation($rotation, $name) {
  animation: $name 0.2s;
  animation-fill-mode: both;

  @keyframes #{$name} {
    0% {
      opacity: 0;
      height: 0;
      width: 0;
      transform: rotate(0deg);
    }

    30% {
      height: $line-width;
      width: $box-hyp;
      transform: rotate(0deg);
    }
    100% {
      opacity: 1;

      transform: rotate($rotation);
    }
  }
}

.close-button {
  height: $box-height;
  width: $box-height;
  transition: all 0.2s ease-out;
  position: relative;
  background-color: transparent;
  z-index: 99999999999;

  &.showing {
    &::before {
      @include closeButtonAnimation(45deg, before);
    }
    &::after {
      @include closeButtonAnimation(-45deg, after);
    }
  }

  &.hidden {
    &::before {
      @include closeButtonAnimation(45deg, before);
      animation-direction: reverse;
    }
    &::after {
      @include closeButtonAnimation(-45deg, after);
      animation-direction: reverse;
    }
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }

  &::before,
  &::after {
    position: absolute;
    top: 45%;
    left: -12%;

    content: '';
    display: inline-block;
    background-color: $color-grey;
    width: $box-hyp;
    height: $line-width;
    opacity: 1;
    transition: all 1s;
  }
}
