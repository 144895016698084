// * THEME COLORS
$color-black: #161616;
$color-grey: #808080;
$color-white: #ffffff;

$color-beige: #c79f57;
$color-blue: #607983;
$color-brown: #3d170b;
$color-burnt: #892b0d;
$color-khaki-dark: #756e61;
$color-khaki-light: #858569;
$color-maroon: #5f1206;
$color-orange: #c65f23;
$color-yellow: #cab43b;

//* BASE COLORS
$color-txt-dm: $color-white;
$color-txt-lm: $color-black;

$color-bkg-dm: $color-black;
$color-bkg-lm: $color-white;

$color-txt: var(--color-txt);
$color-bkg: var(--color-bkg);
$color-ph: var(--color-ph);

// * SHADOWS
$shadow-01: 0px 2px 3px rgba(0, 0, 0, 0.25);
$shadow-02: 2px 0px 4px rgba(0, 0, 0, 0.15);
