.animated-callout {
  position: absolute;
  z-index: 10;
  &__content {
    padding: 1.5rem;

    & p {
      text-align: center;
      color: rgba($color-white, 0.9);

      & .em {
        color: $color-yellow;
      }
    }
  }
}
