.scene-section {
  position: relative;

  &__content {
    @include stickyCanvas;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-bottom: $appstore-height;
  }
}

.swipe-scene {
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  height: 100%;
  width: 100vw;
  @include respond(pc) {
    max-width: $pc-max-width;
  }

  & > * {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}
