.load-wrapper {
  width: 100%;
  @include flexCenter(column);

  &.abs {
    @include horCenter;
    top: 3rem;
  }

  &.absAll {
    @include absCenter;
  }
}
