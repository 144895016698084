.check-cloud {
  margin-top: 2rem;
  margin-bottom: 2rem;

  @include respond(pc) {
    margin-top: 3rem;
  }

  &.last {
    margin-bottom: 0;
  }

  & h4 {
    margin-bottom: 2rem;
  }

  &__txt {
    @include body_p_sm;
  }
  &__boxes {
    display: flex;
    flex-wrap: wrap;
    column-gap: 3rem;
    row-gap: 1.6rem;

    @include respond(pc) {
      column-gap: unset;

      & .checkbox:not(:last-of-type) {
        margin-right: 3rem;
      }
    }
  }

  &__other {
    margin-top: 1.4rem;
    display: flex;
    justify-content: space-between;
    & input {
      max-width: 70%;
    }

    @include respond(pc) {
      justify-content: flex-start;
    }

    & > *:not(:last-child) {
      margin-right: 1rem;
    }
  }
}
