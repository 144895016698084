footer {
  width: 100%;
  padding-top: 6rem;
  text-align: center;
  @include respond(pc) {
    padding: 3rem;
    padding-bottom: calc($appstore-height + 3rem);
  }

  @include respond(ssp) {
    padding-top: 0;
  }

  @include respond(tab) {
    padding-bottom: 0;
  }
  & a {
    // text-decoration: none;
    color: $color-black;
  }

  & p {
    @include respond(pc) {
      font-size: 1.8rem;

      &.sm {
        font-size: 1.2rem;
      }
    }
  }
}
