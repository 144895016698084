.birthday-input {
  display: flex;
  width: 100%;

  justify-content: space-between;
  align-items: baseline;
  gap: 0.7rem;

  @include respond(pc) {
    justify-content: flex-start;
  }

  &__wrapper {
    margin: 1rem 0;
    width: 100%;
  }

  &__component {
    @include body_p;
    display: flex;
    align-items: baseline;
    width: 100%;
    gap: 0.5rem;
    @include respond(pc) {
      width: auto;
    }
  }

  & select {
    @include body_p;
    padding: 0 1rem;
    height: 3.6rem;
    border-radius: 0.5rem;
    width: 100%;
    @include respond(pc) {
      width: 9rem;
    }

    &.year {
      min-width: 9rem;
      @include respond(pc) {
        min-width: 15rem;
      }
    }
  }

  & label {
    align-self: flex-end;
    margin-top: 1rem;
  }
}
