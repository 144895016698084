$select-border: 3px solid $color-yellow;
$hover-shadow: 0px 0px 6px $color-yellow;

.thread-fork {
  padding: 0;

  display: flex;

  &__option {
    width: 50%;
    overflow: hidden;
    padding-top: 2rem;
    border-radius: 0.5rem;
    transition: box-shadow 0.5s ease;
    &:hover {
      transition: box-shadow 0.5s ease;
      box-shadow: $hover-shadow;
    }

    &.ios {
      padding-left: 2rem;
    }

    &.macos {
      padding-right: 2rem;
    }
  }
  &__sshot {
    width: 80%;

    &.macos {
      width: 160%;
      transform: translateX(-50%);
    }

    &.ios {
      width: 60%;
    }
  }

  &__logo {
    max-width: 50%;
  }
}
