.hero {
  height: 100vh;
  padding-bottom: $appstore-height;

  display: flex;
  flex-direction: column;

  @include respond(pc) {
    align-items: center;
  }

  &__body-wrapper {
    height: 100%;
    width: 100%;
    @include flexCenter(column);
    justify-content: center;
  }

  &__header {
    padding-top: 10rem;
    @include horPadding;
    @include flexCenter(column);

    position: relative;
    width: 100%;
    text-transform: uppercase;

    @include respond(pc) {
      padding-top: 4rem;

      & h2 {
        @include body_h4;
      }
    }
  }
  &__logo {
    width: 70%;
    margin-bottom: 2rem;

    @include respond(pc) {
      width: 22rem;
      margin-bottom: 0;
    }

    @include respond(tab) {
      width: 30rem;
      margin-bottom: 0.5rem;
    }
  }

  &__catch {
    @include body_h1;
    text-align: center;
  }

  &__body {
    position: relative;
    height: 70rem;
    width: 100%;
    @include respond(pc) {
      width: 85rem;
      height: 50rem;
    }
    @include respond(tab) {
      height: 50rem;
    }
  }

  &__app {
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: end;
    overflow-x: hidden;

    &.macos {
      top: 0;
      width: 70rem;
      left: -3rem;
      @include respond(pc) {
        left: 0;
      }

      @include multiRespond(tab, pc) {
        width: 60rem;
        top: 2rem;
        bottom: unset;
      }
    }

    &.ios {
      width: 45rem;
      right: 0;
      top: 17rem;
      // bottom: 22rem;

      @include multiRespond(pc, tab) {
        width: 55rem;
        bottom: unset;
        top: 6rem;
      }

      @include respond(tab) {
        width: 52rem;
      }

      @include respond(ssp) {
        width: 40rem;
        top: 7rem;
      }
    }
  }

  &__ss {
    &.macos {
      left: $section-padding-h;
      width: 100%;
    }

    &.ios {
      width: 27rem;
      margin-left: 4rem;
      @include multiRespond(tab, pc) {
        width: 22rem;
        transform: translateX(50%);
      }
    }

    &.watchos {
      position: absolute;
      width: 40rem;
      bottom: 0rem;
      right: 0;
      transform: translateX(40%);

      @include respond(pc) {
        transform: translateX(0);
        width: 27rem;
        bottom: 4rem;
      }

      @include respond(tab) {
        transform: translateX(25%);
        bottom: 5rem;
        width: 29rem;
      }

      @include respond(ssp) {
        bottom: 5rem;
        width: 35rem;
      }
    }
  }

  &__app-logos {
    position: absolute;
    @include flexCenter(row);
    justify-content: space-between;
    bottom: 0;
    width: 100%;
  }

  &__app-logo {
    width: 20rem;

    &.macos {
      padding-left: $section-padding-h;
    }

    &.ios {
      padding-right: $section-padding-h;
    }
  }
}

.antihero {
  & .scene-section__content {
    width: 100%;
    align-items: center;
    @include respond(pc) {
      width: 95%;
    }
  }

  & .hero__header {
    @include respond(tab) {
      padding-top: 0;
    }
  }

  & .hero__app {
    // &.ios {
    //   bottom: 12rem;
    // }

    // &.macos {
    //   bottom: 20rem;
    // }

    &.ios,
    &.macos {
      @include respond(pc) {
        bottom: unset;
      }
    }
  }
}
