@mixin absCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin unsetAbsCenter {
  position: unset;
  top: unset;
  left: unset;
  transform: unset;
}

@mixin vertCenter {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

@mixin horCenter {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}

@mixin flexCenter($direction) {
  display: flex;
  flex-direction: $direction;
  align-items: center;
}

@mixin flexGapHor($mar) {
  & > * :not(:last-child) {
    margin-right: $mar;
  }
}

@mixin flexGapVert($mar) {
  & > * :not(:last-child) {
    margin-bottom: $mar;
  }
}

@mixin absFill {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
