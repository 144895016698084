.ios-watch {
  @include flexCenter(column);
  height: 100%;
  padding-top: 12rem;
  padding-bottom: 6rem;
  @include respond(pc) {
    padding-top: 6rem;
    padding-bottom: unset;
  }

  @include respond(ssp) {
    padding-top: 8rem;
    padding-bottom: unset;
  }

  @include respond(tab) {
    padding-top: 8rem;
  }

  & p {
    font-size: 3.4rem;
    @include respond(pc) {
      @include body_p;
    }
  }

  & h3 {
    @include body_h2;
    // margin-bottom: 4rem;

    @include respond(pc) {
      @include body_h3;
      margin-bottom: 1rem;
    }

    @include respond(ssp) {
      margin-bottom: 1rem;
    }
  }

  & h2 {
    @include body_h1;
    font-size: 4.5rem;

    margin-bottom: 4rem;
    @include respond(pc) {
      margin-bottom: 3rem;
      @include body_h2;
    }

    @include respond(ssp) {
      margin-bottom: 0;
    }
  }
  &__sukusho {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
    @include respond(pc) {
      @include flexCenter(row);
      align-items: flex-end;
      gap: 3rem;
    }
  }

  &__section {
    position: absolute;
    padding-left: 8rem;

    @include respond(pc) {
      position: relative;
    }

    &--remote {
      width: 75%;
      bottom: 0;

      @include respond(pc) {
        width: 40rem;
        height: auto;
      }

      @include respond(tab) {
        height: auto;
        display: flex;
        width: 100%;
        flex-direction: row-reverse;
        align-items: center;
      }
    }

    &--cast {
      @include respond(pc) {
        width: 60%;
        align-self: flex-start;
        padding-left: 0;
      }

      @include respond(tab) {
        width: 100%;
        right: 0;
      }
    }
  }

  &__cast-img {
    width: 100%;
    transform: translate(50%, 10%) rotate(-40deg);
    @include respond(pc) {
      width: 40rem;
      transform: translate(65%, 10%) rotate(-40deg);
    }

    @include respond(tab) {
      width: 40rem;
      transform: translate(100%, 10%) rotate(-40deg);
    }
  }

  &__remote-img {
    width: 100%;
    @include respond(tab) {
      width: 30rem;
    }
  }

  &__subtext {
    z-index: 1;

    & p {
      opacity: 0;
    }
    &--cast {
      position: absolute;
      top: 5%;
      left: 15%;
      width: 60%;

      @include respond(pc) {
        top: 0;
        left: 0;
      }
    }

    &--remote {
      margin-left: 2rem;
      margin-bottom: 1rem;
      max-width: 60%;

      @include respond(pc) {
        max-width: 70%;
        position: absolute;
        bottom: 12rem;
        right: -75%;
      }
      @include respond(tab) {
        max-width: 100%;
      }
    }
  }
}

// .ios-watch {
//   @include flexCenter(column);
//   height: 100%;
//   &__sukusho {
//     display: flex;
//     flex-direction: row;
//     align-items: flex-end;

//     height: 100%;
//     width: 100%;
//   }

//   & img {
//     object-fit: contain;
//     max-width: 50%;
//     max-height: 50%;
//   }
// }
