.terms-agreement {
  @include flexCenter(column);
  margin-top: 4rem;
  &__text {
    padding: 0 1.5rem;
    margin-bottom: 1.6rem;
    height: 52.4rem;
    overflow-y: scroll;
    background-color: $color-white;
  }
  & .terms-title {
    margin-top: 1.3rem;
    margin-bottom: 2rem;
    text-align: center;
  }
}
