@mixin respond($breakpoint) {
  @if $breakpoint == sp {
    @media only screen and (max-width: pxToEM(calc($bkp-tab - 1))) {
      @content;
    }
  }
  @if $breakpoint == ssp {
    @media only screen and (max-height: pxToEM($bkp-ssp)) and (max-width: pxToEM(calc($bkp-tab - 1))) {
      @content;
    }
  }
  @if $breakpoint == tab {
    @media only screen and (min-width: pxToEM($bkp-tab)) and (max-width: pxToEM(calc($bkp-pc - 1))) {
      @content;
    }
  }
  @if $breakpoint == pc {
    @media only screen and (min-width: pxToEM(calc($bkp-pc))) {
      @content;
    }
  }

  //* DARK MODE
  @if $breakpoint == dark {
    @media (prefers-color-scheme: dark) {
      @content;
    }
  }
}

@mixin multiRespond($breakpoints...) {
  @each $breakpoint in $breakpoints {
    @include respond($breakpoint) {
      @content;
    }
  }
}

@mixin screen-less-than($size) {
  @media only screen and (max-width: pxToEM($size)) {
    @content;
  }
}

@mixin auto-respond-sp($size) {
  @media only screen and (max-width: pxToEM($size)) {
    font-size: calc(62.5% * ($size / $res-width-sp));
    -webkit-text-size-adjust: none;
  }
}

@mixin auto-respond-pc($size) {
  @media only screen and (max-width: pxToEM($size)) {
    font-size: calc(62.5% * ($size / $res-width-pc));
    -webkit-text-size-adjust: none;
  }
}

// @mixin set-max-pc($size) {
//   @media only screen and (min-width: #{$size / 16}em) {
//     font-size: calc(62.5% * (1440 / 1920));
//     -webkit-text-size-adjust: none;
//   }
// }
