@mixin btn_wipe($color, $txtColor) {
  overflow: hidden;
  position: relative;
  @include respond(pc) {
    z-index: 1;
    transition: color 150ms ease-in-out;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      display: block;
      width: 0;
      height: 100%;
      background: $color;
      z-index: -1;
      transition: all 150ms ease-in-out;
    }

    &:hover {
      color: $txtColor;
      &:after {
        height: 110%;
        width: 110%;
        opacity: 1;
      }
    }
  }
}

@mixin btn_leftwipe($color, $txtColor) {
  @include btn_wipe($color, $txtColor);
  @include respond(pc) {
    &:after {
      left: 0;
    }
  }
}

@mixin btn_centerwipe($color, $txtColor: white) {
  @include btn_wipe($color, $txtColor);
  @include respond(pc) {
    &:after {
      @include absCenter();
    }
  }
}

@mixin btn_color_trans($color, $txtColor) {
  @include btn_wipe($color, $txtColor);

  @include respond(pc) {
    &:after {
      @include absCenter;
      transition: all 600ms ease-in-out;
      width: 95%;
      height: 90%;
      opacity: 0;
    }
  }
}
