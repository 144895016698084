.ios-phone {
  @include flexCenter(column);
  justify-content: space-between;
  height: 100%;
  padding-top: 12rem;

  @include multiRespond(tab, pc, ssp) {
    padding-top: 6rem;
  }
  @include respond(pc) {
    flex-direction: row; /* Setting direction to row */
    justify-content: space-around;
    align-items: flex-end; /* Align items to the bottom */
    @include horPadding;
  }
  &__sukusho {
    max-height: 75%;

    @include respond(pc) {
      width: 35%;
      height: 100%;
      max-height: 100%;
      max-width: 40%;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
    }

    & img {
      width: 100%;
      object-fit: contain;
      @include respond(pc) {
        max-height: 100%;
      }
    }
  }

  &__sukusho-wrapper {
    // max-height: 100%;
    max-width: 60rem;
    @include respond(tab) {
      width: 40rem;
      @include flexCenter(column);
    }
  }

  & h2 {
    opacity: 0;
    @include body_h1;
    margin-bottom: 2rem;
    @include respond(pc) {
      @include body_h2;
    }
  }

  &__header {
    @include flexCenter(column);
    @include horPadding;
    text-align: center;
    justify-content: center;

    @include respond(pc) {
      height: 100%;

      max-width: 45rem;
      text-align: left;
      align-items: start;
      padding: 0;
    }

    & p {
      max-width: 60rem;
    }
  }
}
