select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:disabled {
    opacity: 0.3;
  }
}

.form-wrapper {
  --bkg-color: $color-grey;
  --border-color: $color-black;

  padding: 2rem;
  width: 100%;
  @include flexCenter(column);
  align-items: stretch;
  background-color: var(--bkg-color);
  border: 1px solid var(--border-color);

  @include respond(pc) {
    padding: 4rem 5.5rem 0 5.5rem;
  }
}

.form__notes {
  width: 100%;

  & textarea {
    width: 100%;
  }

  & p {
    margin-bottom: 0.5rem;
  }
}

.form-header {
  &:not(:first-of-type) {
    margin-top: 3rem;
  }

  margin-bottom: 2rem;
  text-align: center;
  & > h1 {
    color: $color-black;
    @include unsetAbsCenter;
    padding-bottom: 2rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid $color-black;
  }
}

.form-field {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0.5rem;
}

.form-section {
  width: 100%;
  display: flex;

  &__name-wrapper {
    display: flex;
    align-items: stretch;
  }
  &__name {
    width: 100%;
    display: flex;
    flex-direction: column;

    @include respond(pc) {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(45%, 1fr));
      grid-column-gap: 3rem;
    }
  }
}

.address-form {
  &__wrapper {
    margin-top: 1rem;
    width: 100%;
  }
  max-width: 100%;
  &__zip-pref {
    display: flex;
    gap: 1.5rem;

    & .form-field {
      max-width: 50%;
      @include respond(pc) {
        width: 15rem;
        margin-right: 3rem;
      }
    }
    & input {
      height: 3.6rem;
    }
  }
}

input[type='file'] {
  display: none;
}

.file-upload {
  margin-top: 1rem;
  margin-bottom: 1rem;
  @include flexCenter(row);
  width: 100%;
  @include respond(pc) {
    width: 50rem;
  }

  &__label {
    @include inputStyle;
    @include body_p;
    padding: 1rem 2rem;
    width: 100%;
    margin-right: 1.5rem;
  }
  &__button {
    position: relative;
    padding: 0.5rem;
    border: 1px solid #ccc;
    display: inline-block;
    min-width: 4.3rem;
    height: 4.3rem;
    min-height: 4.3rem;
    max-height: 4.3rem;
    border-radius: 0.6rem;
    background-color: $color-black;
    color: $color-white;
    cursor: pointer;

    @include btn_centerwipe($color-white);

    & i {
      @include absCenter;
      font-size: 2.5rem;
    }
  }
}

.terms-agreement {
  @include flexCenter(column);
  margin-top: 4rem;
  &__text {
    padding: 0 1.5rem;
    margin-bottom: 1.6rem;
    height: 52.4rem;
    overflow-y: scroll;
    background-color: $color-white;
  }
  & .terms-title {
    margin-top: 1.3rem;
    margin-bottom: 2rem;
    text-align: center;
  }
}

.form-contact {
  margin-bottom: 2rem;
  @include respond(pc) {
    display: flex;

    & .email {
      margin-right: 3rem;
    }
  }

  & .tel {
    @include respond(pc) {
      width: 23rem;
    }
  }
}

.form-submit-btn__wrapper {
  width: 100%;
  padding: 3rem;
  @include flexCenter(column);
}
