// * DEFINITIONS
$font-title: 'League Spartan', sans-serif;
$font-main: 'League Spartan', sans-serif;

@mixin font($fs, $fw, $lh) {
  font-size: $fs;
  font-weight: $fw;
  line-height: $lh;
}

@mixin titleFont() {
  font-family: $font-title;
  color: $color-yellow;
  letter-spacing: 0.15rem;
}

//headers
@mixin body_h1 {
  @include font(4rem, 600, 5rem);
  @include titleFont();
  @include respond(tab) {
    @include font(3rem, 600, 4rem);
  }
}

@mixin body_h2 {
  @include font(3.8rem, 600, 4.8rem);
  @include titleFont();
  @include respond(tab) {
    @include font(3rem, 600, 4rem);
  }
}

@mixin body_h3 {
  @include font(3.2rem, 600, 4.8rem);
  @include titleFont();
}

@mixin body_h4 {
  @include font(2.8rem, 600, 4.2rem);
  @include titleFont();
}

//paragraph
@mixin body_p {
  @include font(2.8rem, 400, 3.8rem);
  @include respond(tab) {
    @include font(2.4rem, 600, 3.5rem);
  }
}

@mixin body_p_sm {
  @include font(2.2rem, 400, 3.2rem);
}

@mixin body_p_min {
  @include font(1.8rem, 400, 2.8rem);
}

//other
@mixin body_btn {
  @include font(2.4rem, 700, 2.4rem);
}

@mixin body_btn_lg {
  @include font(2.8rem, 700, 2.8rem);
}

// * SET BASE TYPOGRAPHY
body {
  font-family: $font-main;
  letter-spacing: -0.015em;
}

h1 {
  @include body_h1;
}

h2 {
  @include body_h2;
}

h3 {
  @include body_h3;
}

h4 {
  @include body_h4;
}

p {
  @include body_p;
  white-space: pre-wrap;
  color: $color-brown;

  &.sm {
    @include body_p_sm;
  }

  &.min {
    @include body_p_min;
  }
}

button {
  @include body_btn;

  &.lg {
    @include body_btn_lg;
  }
}

.glow {
  text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.8);
}

.shadow {
  text-shadow: 1px 0px 0px rgba(black, 0.3);
}
