@mixin resetButton {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

@mixin customLi {
  margin-left: 1rem;
  list-style: none;
  position: relative;
  list-style-position: outside;
  &:before {
    position: absolute;
    left: -1.2rem;
    content: '・';
  }
}

@mixin inputStyle {
  border-radius: 0.5rem;
  border: 1px solid $color-grey;
  height: 4.8rem;
  padding: 1.5rem;
  background-color: $color-white;
}

@mixin stickyCanvas {
  position: sticky;
  top: 0;
  height: 100vh;
  overflow: hidden;
}

@mixin horPadding {
  padding-left: $section-padding-h;
  padding-right: $section-padding-h;
}
