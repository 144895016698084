.pc-watch {
  width: 100%;
  height: 100%;
  padding-top: 8rem;
  overflow: hidden;
  @include flexCenter(column);
  @include respond(pc) {
    padding-top: 3rem;
  }

  &__header {
    width: 100%;
    @include flexCenter(row);
    white-space: pre-wrap;
    z-index: 2;

    @include respond(pc) {
      position: absolute;
      top: 3rem;
    }

    & > * {
      @include horPadding;
      width: 100%;
      flex-shrink: 0;
    }
  }

  &__logo {
    padding-left: 6rem;
    overflow: hidden;
    @include respond(pc) {
      max-width: 50%;
    }

    @include respond(tab) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    & img {
      width: 70%;
      object-fit: contain;
      @include respond(pc) {
        width: 100%;
      }

      @include respond(tab) {
        width: 60%;
      }
    }

    @include respond(pc) {
      position: absolute;
      right: 6rem;
      top: 6rem;
    }
  }

  &__sukusho {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
    @include respond(pc) {
      width: 80%;
      overflow: visible;
    }

    & img {
      object-fit: contain;
    }
  }

  &__image {
    position: absolute;

    & img {
      width: 100%;
      max-height: 100%;
    }

    &--macos {
      width: 100%;
      top: 16%;
      left: -6rem;

      @include respond(pc) {
        width: 70%;
        left: 0;
        top: unset;
        bottom: 6rem;
      }
      @include respond(tab) {
        width: 80%;
        top: unset;
        bottom: 6rem;
      }
    }

    &--ios {
      height: 90%;
      top: 5%;
      left: 6%;

      & img {
        width: auto;
      }

      @include respond(pc) {
        left: 10%;
      }
    }

    &--watchos {
      width: 80%;
      top: 26%;
      right: -11rem;
      @include respond(pc) {
        width: 50%;
        right: 0;
        top: unset;
        bottom: 3rem;
      }
      @include respond(tab) {
        width: 60%;
        right: 0;
        top: unset;
        bottom: 3rem;
      }
    }
  }

  &__popup {
    &--vlc {
      top: -6rem;
      left: -3rem;
      @include respond(pc) {
        left: -6rem;
        top: -7rem;
      }
    }

    &--cast {
      bottom: 4rem;
      width: 40rem;
      left: -20rem;

      @include respond(pc) {
        width: 60rem;
        left: -25rem;
      }
    }
  }
}
