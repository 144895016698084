.checkbox {
  @include flexCenter(row);

  & input {
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin-right: 0.8rem;
    width: 1.9rem;
    height: 1.9rem;
    padding: 0;
    border-radius: 0.5rem;
    border: 1px solid $color-grey;
    background-color: $color-white;
    cursor: pointer;
  }

  & input:checked {
    &:after {
      content: '✔︎';
      @include absCenter;
      color: $color-black;
    }
  }
}
