.home {
  & .section-content {
    padding: $section-padding;
    width: 100%;
    @include respond(pc) {
      max-width: 120rem;
      padding: 7rem 10rem;
    }
  }

  &__buffer-top {
    height: 30rem;
    @include respond(pc) {
      height: 13rem;
    }
  }
}
