.address-form {
  &__wrapper {
    margin-top: 1rem;
    width: 100%;
  }
  max-width: 100%;
  &__zip-pref {
    display: flex;
    gap: 1.5rem;

    & .form-field {
      max-width: 50%;
      @include respond(pc) {
        width: 15rem;
        margin-right: 3rem;
      }
    }
    & input {
      height: 3.6rem;
    }
  }
}
